section.postani-section {
  .postani-wrapper{
    padding-top: 80px;
    @include max-mq( 767px ){
      padding-top: 50px;
    }
  }
  .postani-inner {
    max-width: 1240px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    z-index: 1;
    @include max-mq( 1024px ){
      padding-left: 30px;
      padding-right: 30px;
   }
  }
  .heading-wrapper {
    text-align: center;
    padding-bottom: 55px;
    @include max-mq( 767px ){
      padding-bottom: 30px;
    }
    @include max-mq( 520px ){
      padding-bottom: 30px;
      max-width: 222px;
      margin: auto;
    }
    h2,h3,h4 {
      color: $main-red;
      margin: 0;
    }
  }
  .four-boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    @include max-mq( 1024px ){
      max-width: 640px;
      width: 100%;
      margin: auto;
   }
    .box {
      padding: 25px 20px 0;
      max-width: 290px;
      width: 100%;
      @include max-mq( 1200px ){
         max-width: 270px;
      }
      @include max-mq( 1100px ){
         max-width: 250px;
      }
      @include max-mq( 1024px ){
         max-width: 50%;
         width: 100%;
         justify-content: center;
         padding: 25px 5px 0;
      }
    }
    .box-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 35px 40px;
      border: 1px solid $main-red;
      background-color: $main-white;
      transition: all 0.4s;
      min-height: 215px;
      width: 100%;
      cursor: pointer;
      position: relative;
      text-align: center;
      @include max-mq( 1024px ){
        max-width: 250px;
        margin: auto;
      }
      @include max-mq( 640px ){
        padding: 20px 15px 15px;
        min-height: 140px;
      }
      &:hover{
         box-shadow: 10px 10px 10px 0px rgba(210,196,196,0.6);
      }
      >div{
         width: 100%;
         text-align: center;
      }
      img{
         display: block;
         margin: auto;
         @include max-mq( 640px ){
          max-width: 44px;
        }
      }
      a{
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
      }
      h6{
         text-align: center;
         margin-bottom: 0;
         margin-top: 5px;
         width: 100%;;
      }
    }
  }
}
