main{
   ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 20px;
        letter-spacing: 0.03em;
        line-height: 45px;
        position: relative;
        padding-left: 15px;
        @include max-mq( 1024px ){
          font-size: 18px;
          line-height: 38px;
        }
        &::before {
          content: "-";
          top: 15px;
          left: 0;
          position: absolute;
          height: 13px;
          width: 13px;
          line-height: 10px;
        }
      }
    }
    b,
    strong {
      font-weight: $fw-bold;
    }
    a {
      color: $main-red;
      &:hover {
        color: $main-orange;
      }
    }
    
}