/**************************************************
* Media query for device minimal width
**************************************************/
@mixin min-mq( $var) {
  @media (min-width: #{$var}) {
    @content;
  }
}
/**************************************************
* Media query for device maximum width
**************************************************/
@mixin max-mq( $var) {
  @media (max-width: #{$var}) {
    @content;
  }
}
/**************************************************
* Media query for device minimal and maximum width
**************************************************/
@mixin min-max-mq( $min, $max) {
  @media (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}