.slick-arrow {
  background-color: transparent;
  border: none;
  bottom: 0;
  color: $main-dark;
  font-size: 30px;
  height: 35px;
  line-height: 35px;
  margin: auto 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 35px;
  z-index: 10;

  &.slick-prev-lf {
    left: 0;
  }

  &.slick-next-lf {
    right: 0;
  }
}

.slick-dots {
  margin: 20px 0 0;
  padding-left: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 12px;
    vertical-align: middle;
    padding: 0;
    @include max-mq(1024px){
      margin: 0 25px;
    }
    &::after,
    &:before{
      content: none;
      display: none;
    }
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }

    button {
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid;
      color: transparent;
      font-size: 0;
      height: 17px;
      padding: 0;
      width: 17px;
      border: 1px solid $main-white;
      transition: all 0.4s;
      text-decoration: none;
    }

    &.slick-active {

      button {
        background-color: $main-white;
      }
    }
  }
}
