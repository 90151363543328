footer#main-footer {
  .footer-wrapper {
    background-color: $main-white;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .footer-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    @include max-mq(991px){
      flex-direction: column;
    }
  }
  .footer-logo{
    @include max-mq(991px){
      order: 1;
    }
    img{
      display: block;
      @include max-mq(1024px){
        max-width: 275px;
      }
    }
  }
  .copyrights{
    @include max-mq(991px){
      order: 3;
      max-width: 150px;
    }
    p{
      text-align: center;
      letter-spacing: 0.05em;
      font-weight: $fw-regular;
      color: $footer-text;
      margin: 0;
      font-size: 14px;
      @include max-mq(991px){
        line-height: 24px;
      }
    }
  }
  .social-icons {
    @include max-mq(991px){
      order: 2;
      padding: 70px 0;
    }
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    a{
      display: flex;
      width: 75px;
      height: 75px;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      color: $main-orange;
      border: 1px solid $main-orange;
      transition: all 0.4s;
      font-size: 20px;
      border-radius: 50%;
      margin-left: 22px;
      text-decoration: none;
      &:first-of-type{
        margin-left: 0;
      }
      &:hover{
        background-color: $main-orange;
        color: $main-white;
      }
    }
  }
}

