section.split-section{
   .split-wrapper{
      margin-top: -65px;

   }
   .split-inner{
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      flex-wrap: wrap;
      &:before{
         content: '';
         position: absolute;
         width: 230px;
         height: 230px;
         display: inline-block;
         border: 62px solid $main-red;
         background-color: $main-orange;
         top: 190px;
         left: -116px;
         transform: rotate(45deg);
         @include max-mq(1600px){
            border-width: 55px;
            width: 200px;
            height: 200px;
         }
         @include max-mq(1500px){
            border-width: 35px;
            width: 150px;
            height: 150px;
            left: -90px;
         }
         @include max-mq(1450px){
            border-width: 15px;
            width: 130px;
            height: 130px;
         }
         @include max-mq(1380px){
            width: 100px;
            height: 100px;
            left: -70px;
         }
         @include max-mq(1280px){
           content: none;
           display: none;
         }
      }
      .split-column-wrapper{
         width: 50%;
         padding: 180px 20px;
         display: flex;
         align-items: center;
         flex-direction: row;
         @include max-mq(1200px){
            padding: 150px 35px;
         }
         @include max-mq(1024px){
            padding: 115px 35px;
         }
         @include max-mq(767px){
            width: 100%;
         }
         &:first-child{
            justify-content: flex-end;
            padding-right: 135px;
            background-color: $blue-gray;
            @include max-mq(1300px){
               padding-right: 10%;
            }
            @include max-mq(1200px){
               padding-right: 35px;
            }
            @include max-mq(767px){
               padding-bottom: 40px;
            }
         }
         &:last-child{
            justify-content: flex-start;
            padding-left: 135px;
            background-color: $silver;
            @include max-mq(1300px){
               padding-left: 10%;
            }
            @include max-mq(1200px){
               padding-left: 35px;
            }
            @include max-mq(767px){
               padding-top: 60px;
               padding-bottom: 40px;
            }
         }
      }
   }
   .split-column-inner{
      max-width: 485px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      @include max-mq(767px){
         max-width: 100%;
         padding: 0;
      }
      h2,h3,h4,h5,h6{
         color: $main-red;
      }
      h2{
         margin-top: 0;
         margin-bottom: 30px;
      }
      ul{
         margin-left: -15px !important;
      }
   }
}