/**************************************************
* In this file call fonts and set all variables
**************************************************/
// @import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,300,300i,400,400i,500,500i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,300,300i,400,400i,500,500i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');
// Colors:
$main-dark: #000;
$main-white: #fff;
$gray: #67696B;
$main-orange: #EB6800;
$main-red: #DA0810;
$footer-text: #3D3D3D;
$main-text-color: #6A6A6A;
$blue-gray: #EAEEF3;
$silver: #F5F5F5;
$overlay-dark: #272933;
$input-color: #F1F1F1;
$dark-bg: #515367;

/*Font Variables*/
$primary-font: 'Montserrat', sans-serif;
$secondary-font: sans-serif;

//Font weight
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 400;
$fw-bold: 700;

//Font Size
$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 14px;

$font-size-h1: 50px;
$h1-line: 55px;
$font-size-h2: 36px;
$h2-line: 1;
$font-size-h3: 30px;
$h3-line: 1;
$font-size-h4: 20px;
$h4-line: 1;
$font-size-h5: 18px;
$h5-line: 1;
$font-size-h6: 16px;
$h6-line: 24px;
$heading-line-height: 1;

$font-size-h1-1024: 30px;
$h1-line-1024: 35px;
$font-size-h2-1024: 30px;
$h2-line-1024: 35px;
$font-size-h3-1024: 24px;
$h3-line-1024: 34px;
$font-size-h4-1024: 20px;
$h4-line-1024: 1;
$font-size-h5-1024: 16px;
$h5-line-1024: 1;
$font-size-h6-1024: 14px;
$h6-line-1024: 16px;


// Breakpoints
$mac: 1440px;
$lt: 1366px;
$ls: 1280px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$mb: 480px;
$ip: 420px;
$xs: 320px;

$container_width: 1170px;
