.social-wrapper{
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            list-style: none;
            padding: 0;
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px;
            &::after,
            &::before{
                content: none;
            }
        }
    }
}