#site-navigation {
  position: relative;
  ul {
    @extend %clearfix;
    list-style: none;
    margin: 0;
    padding-left: 0;

    li:hover > ul,
    li.focus > ul {
      left: auto;
    }
  }
  .menu-item {
    float: left;
    position: relative;
    padding: 0 27px;
    @include max-mq(1024px) {
      float: left;
      text-align: left;
      width: 100%;
      padding: 0 0 10px 0;
      &:last-of-type{
        padding-bottom: 0;
      }
    }

    .arrow-toggle {
      color: $main-dark;
      cursor: pointer;
      display: inline-block;
      font-size: 23px;
      height: 100%;
      margin-right: 0;
      padding: 1px 21px;
      position: absolute;
      z-index: 99;
      right: 0;
      top: 0;
    }

    &.menu-item-has-children {
      @include max-mq(1024px){
        transition: all 0.4s;
      }
      &.expandeds {
        > .arrow-toggle {
          > .fa {
            &:before {
              content: "\f106";
            }
          }
        }
      }
    }
    &.current-menu-item{
      >a{
        color: red;
      }
    }
    &:hover {
      > .sub-menu {
        visibility: visible;
        z-index: 9999;
      }
    }
  }

  a {
    display: block;
    text-decoration: none;
    color: $main-red;
    padding: 5px 0;
    position: relative;
    font-size: 18px;
    font-weight: $fw-bold;
    transition: all 0.4s;
    letter-spacing: 0.03em;
    @include max-mq(1024px){
      padding: 10px 0;
    }
    &::after{
      content: '';
      bottom: 0;
      left: 0;
      width: 0px;
      transition: all 0.4s;
      position: absolute;
      background-color: $main-red;
      height: 3px;
      @include max-mq(1024px){
        content: none;
      }
    }
    &:hover{
      &::after{
        width: 40px;
      }
    }
  }
  .sub-menu {
    background-color: $main-white;
    float: left;
    left: -999em;
    position: absolute;
    top: auto;
    z-index: -1;
    visibility: hidden;
    a {
      width: 200px;
    }
    @include max-mq(1024px) {
      display: none;
      width: 100%;
      margin-left: 0;
      padding-top: 0;
      top: 0;
      left: 0;
      position: relative;
    }
  }

  > div {
    @include max-mq(1024px) {
      background-color: $main-white;
      position: fixed;
      transition: all 0.4s;
      width: 100%;
      z-index: 9999;
      height: 0px;
      top: 70px;
      left: 0;
      overflow: hidden;
      padding: 0 35px;
      visibility: hidden;
    }
  }

  
  &.toggled > div {
    height: 190px;
    visibility: visible;
  }
}
.menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  color: $main-dark;
  float: right;

  @include max-mq(1024px) {
    display: block !important;
  }
}
