section.resenja-section{
   .resenja-inner{
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      flex-wrap: wrap;
      background-repeat: no-repeat;
      background-image: url('../images/lines-bg.png');
      background-position: left calc(100% + 35px);
      @include max-mq(1600px){
         background-size: 10%;
      }
      @include max-mq(1300px){
         background-image: none;
      }

      .column-wrapper{
         width: 50%;
         padding: 100px 20px 125px ;
         display: flex;
         flex-direction: row;
         align-items: flex-start;
         @include max-mq(1024px){
            padding: 60px 35px;
         }
         @include max-mq(767px){
            width: 100%;
         }
         &:first-child{
            justify-content: flex-end;
            padding-right: 135px;
            @include max-mq(1300px){
               padding-right: 10%;
            }
            @include max-mq(1200px){
               padding-right: 35px;
            }

            @include max-mq(767px){
               order: 2;
               padding-top: 20px;
            }
         }
         &:last-child{
            justify-content: flex-start;
            padding-left: 0;
            @include max-mq(767px){
               order: 1;
               padding-left: 35px;
               justify-content: center;
               padding-bottom: 20px;

            }
         }
      }
   }
   .image-wrapper {
      padding: 25px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      @include max-mq(1024px){
         padding: 15px;
      }
      @include max-mq(767px){
         padding: 10px;
      }
      img{
         display: block;
         position: relative;
         z-index: 2;
      }
      &::before,
      &::after{
         content: '';
         position: absolute;
         display: block;
      }
      &::before{
         background-color: $main-orange;
         top: 0;
         right: 0;
         left: 73px;
         bottom: 73px;
      }
      &::after{
         background-color: $main-red;
         bottom: 0;
         left: 0;
         right: 73px;
         top: 73px;
      }
   }
   .content-inner{
      max-width: 485px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 70px;
      @include max-mq(1024px){
         padding-top: 0;
         padding-left: 0;
         padding-right: 0;
      }
      @include max-mq(767px){
         max-width: 100%;
      }
      .link-wrapper{
         padding-top: 10px;
      }
      h2,h3,h4,h5,h6{
         color: $main-red;
      }
      p{
         font-size: 16px;
         letter-spacing: 0.03em;
         line-height: 40px;
      }
      h3{
         margin-top: 0;
         margin-bottom: 55px;
         line-height: 40px;
         letter-spacing: 0.03em;
         @include max-mq(1024px){
            margin-bottom: 30px;
         }
      }
      ul{
         margin-left: -15px !important;
         li{
            font-size: 16px;
            line-height: 40px;
         }
      }
   }
}