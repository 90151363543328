// Define custom Class for project
.big-container{
   max-width: 1480px;
   padding-left: 20px;
   padding-right: 20px;
   margin-left: auto;
   margin-right: auto;
   width: 100%;
}
.btn{
   @extend %button;
}
.fancy-link{
   text-decoration: underline;
   font-weight: $fw-bold;
   font-size: 18px;
   letter-spacing: 0.03em;
   line-height: 50px;
   color: $main-orange;
   transition: all 0.4s;
   padding-right: 25px;
   position: relative;
   display: inline-block;
   &::after{
      content: '';
      width: 12px;
      height: 23px;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url('../images/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
   }
   &:hover{
      padding-right: 40px;
   }
}
.mobile-version {
   @include min-mq(1025px){
      display: none !important;
   }
}
.desktop-version {
   @include max-mq(1024px){
      display: none !important;
   }
}