input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: $main-orange;
  border: 1px solid $main-orange;
  border-radius: 0;
  font-family: $primary-font;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 18px;
  height: 55px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: $main-white;
  font-weight: $fw-regular;
  @include max-mq(460px){
    height: 65px;
  }
}

textarea {
  resize: none;
  padding-left: .6em;
  width: 100%;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  &:not([role="tab"]){
    @extend %button;
  }
}

/*---- Placeholder ----*/
::-webkit-input-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
\:-moz-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
::-moz-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
\:-ms-input-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
*::-webkit-input-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
*:-moz-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
*:-ms-input-placeholder {
  opacity: 1 !important;
  color: $main-orange;
}
