.select2-container--default {
  color: #000;
  font-family: $secondary-font;
  font-size: $font-size-large;

  .select2-selection--single {
    border-radius: 0;
    border: 1px solid;
    height: auto;
    line-height: 1em;
    padding: 14px 25px;

    .select2-selection__rendered {
      font-size: $font-size-large;
      padding: 0;
      text-transform: none;
    }

    .select2-selection__arrow {
      bottom: 0;
      margin: auto;
      right: 20px;
      top: 0;

      b {
        border-color: $main-dark transparent transparent transparent;
        border-width: 6px 5px 0 5px;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  &.select2-container--open {

    .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent $main-dark transparent;
      border-width: 0 5px 6px 5px;
    }
  }
}

.select2-search--dropdown {
  display: block;
  padding: 0 10px;
}

.select2-results__option[aria-selected],
.select2-results__option[data-selected] {
  padding: 5px 20px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: gray;
  color: $main-dark;
}

.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[data-selected=true] {
  background-color: gray;
}
