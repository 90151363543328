header#main-header{
   #Call{
      clip-path: url("#clip-path-call");
    }
   #Call-a{
      clip-path: url("#clip-path-call-a");
    }
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 99999;
   background-color: $main-white;
   .main-header-wrapper{
      padding: 5px 0;
      border-bottom: 1px solid $main-orange; 
      @include max-mq(1024px){
         padding: 8px 0;
         height: 70px;
         display: flex;
         align-items: center;
         flex-direction: row;
         >div{
            width: 100%;
         }
      }
   }
   .main-header-inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      @include max-mq(1024px){
         padding-left: 35px;
         padding-right: 35px;
      }
   }
   .logo-wrapper{
      img{
         max-width: 175px;
         display: block;
         @include max-mq(1024px){
            max-width: 138px;
         }
         @include max-mq(640px){
            max-width: 120px;
         }
      }
   }
   .main-navigation{
      >div{
         display: flex;
         flex-direction: row;
         align-items: center;
      }
   }
   .primary-menu-wrapper{
      padding-right: 75px;
      @include max-mq(1024px){
         width: calc( 100% - 70px );
         padding-right: 0;
      }
   }
   .header-contact{
      display: flex;
      align-items: center;
      flex-direction: row;
      @include max-mq(1024px){
         flex-direction: column;
         width: 70px;
      }
      >div{
         margin-left: 20px;
         &:first-of-type{
            margin-left: 0;
         }
         @include max-mq(1024px){
            margin: 0 0 30px;
            &:last-of-type{
               margin-bottom: 0;
            }
         }
      }
      a{
         padding: 0;
         letter-spacing: 0;
         line-height: 1;
         &::after{
            content: none;
         }
         path{
            transition: all 0.4s;
         }
         &:hover{
            path{
               &:not(.icon-background){
                  fill: $main-white;
               }
               &.icon-background {
                  fill: $main-orange;
               }
            }
         }
      }
   }
}