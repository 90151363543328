// Define custom extensions
%clearfix{
   &:before,
   &:after{
      content: '';
      display: table;
      width: 100%;
   }
}
%button {
   min-width: 215px;
   display: inline-block;
   vertical-align: middle;
   text-decoration: none;
   padding: 21px 15px;
   font-size: 18px;
   font-weight: $fw-bold;
   color: $main-orange;
   border: 2px solid $main-orange;
   background-color: $main-white;
   transition: all 0.4s;
   text-align: center;
   letter-spacing: 0.07em;
   line-height: 1;
   &:hover{
      color: $main-white;
      background-color: $main-orange;
   }
}