section.contact-section {
  position: relative;
  .contact-section-wrapper {
    padding-top: 170px;
    padding-bottom: 100px;
    position: relative;
    background-image: url("../images/contact.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include max-mq(1024px){
      padding-top: 120px;
      padding-left: 35px;
      padding-right: 35px;
    }
    @include max-mq(767px){
      padding-top: 90px;
      padding-bottom: 60px;

    }
    &:before {
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      background-color: rgba($overlay-dark, 0.8);
    }
    .contact-section-inner {
      position: relative;
      z-index: 1;
      text-align: center;
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    h3{
      margin-top: 0;
      margin-bottom: 0;
      color: $main-white;
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -1px;
    border-style: solid;
    z-index: 2;
    border-width: 50px 26vw 50px 26vw;
    @include max-mq(1024px){
      border-width: 25px 26vw 25px 26vw;
    }
    @include max-mq(767px){
      border-width: 15px 26vw 15px 26vw;
    }
  }
  &:before {
    border-color: $blue-gray transparent transparent $blue-gray;
    left: 0;
    @include max-mq(767px){
      border-color: $silver transparent transparent $silver;
    }
  }
  &:after {
    border-color: $silver $silver transparent transparent;
    right: 0;
  }
  .form-wrapper{
    .form-message{
      color: $main-white;
      margin-top: 15px;
      font-size: 20px;
      font-weight: $fw-medium;
      position: absolute;
      bottom: -60px;
      left: 0;
      right: 0;
      @include max-mq(767px){
        font-size: 16px;
      }
      @include max-mq(520px){
        font-size: 13px;
      }
    }
    &.sending{
      .btn{
        &::after{
          opacity: 1 !important;
        }
      }
    }
  }
  .form-inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding-top: 65px;
    @include max-mq(767px){
      padding-top: 55px;
    }
    .input-wrapper {
      width: calc(50% - 35px);
      margin-bottom: 40px;
      position: relative;
      @include max-mq(640px) {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
    .submit-button-wrapper {
      padding-top: 20px;
      width: 100%;
      text-align: center;
    }
    .btn{
      position: relative;
      &:hover{
        &::after{
          border-color: $main-white transparent $main-white transparent;
        }
      }
      &::after{
        content: '';
        bottom: 0;
        top: 0;
        right: 15px;
        width: 15px;
        height: 15px;
        margin: auto;
        position: absolute;
        border: 3px solid ;
        border-radius: 50%;
        border-color: $main-orange transparent $main-orange transparent;
        animation: lds-dual-ring 1.2s linear infinite;
        opacity: 0;
      }
    }
    label.error{
      position: absolute;
      color: $main-red;
      font-weight: $fw-bold;
      font-size: 12px;
      left: 0;
      bottom: -25px;
    }
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
