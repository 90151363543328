section.about-section{
   .about-inner{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .about-image-wrapper{
         width: 42%;
         background-image: url('../images/about.jpg');
         background-position: center;
         background-repeat: no-repeat;
         background-size: cover;
         position: relative;
         overflow: hidden;
         @include max-mq(991px){
            width: 100%;
            height: 500px;
         }
         @include max-mq(640px){
            height: 280px;
         }
         &:before{
            content: '';
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            position: absolute;
            z-index: 1;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#172c54+0,06142e+60&0.85+0,0.01+60 */
            background: -moz-linear-gradient(left,  rgba(23,44,84,0.75) 0%, rgba(6,20,46,0.01) 60%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(23,44,84,0.75) 0%,rgba(6,20,46,0.01) 60%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(23,44,84,0.75) 0%,rgba(6,20,46,0.01) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9172c54', endColorstr='#0306142e',GradientType=1 ); /* IE6-9 */
            @include max-mq(991px){
               background: -moz-linear-gradient(top,  rgba(23,44,84,0.75) 0%, rgba(6,20,46,0.01) 60%); /* FF3.6-15 */
               background: -webkit-linear-gradient(top,  rgba(23,44,84,0.75) 0%,rgba(6,20,46,0.01) 60%); /* Chrome10-25,Safari5.1-6 */
               background: linear-gradient(to bottom,  rgba(23,44,84,0.75) 0%,rgba(6,20,46,0.01) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
               filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9172c54', endColorstr='#0306142e',GradientType=1 ); /* IE6-9 */
            }
         }
         .top-arrow,
         .bottom-arrow{
            position: absolute;
            display: block;
            right: -1px;
            border-style: solid;
            z-index: 2;
            @include max-mq(991px){
               border-width: 25px 27vw 25px 27vw !important;
            }
            @include max-mq(767px){
               border-width: 15px 27vw 15px 27vw !important;
            }
         }
         .top-arrow{
            border-color: $dark-bg  transparent $dark-bg transparent;
            border-width: 370px 0 0 130px; 
            top: 0;
            @include max-mq(991px){
               left: 0;
               right: auto;
               top: auto;
               bottom: -3px;
               display: block;
               border-color: transparent  transparent $dark-bg  $dark-bg;

            }
         }
         .bottom-arrow{
            border-color: $dark-bg  transparent $dark-bg transparent;
            border-width: 0 0 370px 130px; 
            bottom: 0;
            @include max-mq(991px){
               bottom: -3px;
               display: block;
               border-color: transparent $dark-bg $dark-bg transparent;
            }
         }
      }
      .about-content-wrapper{
         width: 58%;
         padding-left: 7.3%;
         display: flex;
         align-items: center;
         flex-direction: row;
         padding-top: 120px; 
         padding-bottom: 120px;
         background-color: $dark-bg;
         background-position: right bottom;
         background-repeat: no-repeat;
         background-image: url('../images/about-bottom-bg.svg');
         @include max-mq(1024px){
            background-image: none;
            padding: 60px 35px;
         }
         @include max-mq(991px){
            width: 100%;
         }
         @include max-mq(767px){
           padding-top: 45px;
         }
         .contant-info-wrapper{
            margin-top: 30px;
            padding-top: 15px;
            border-top: 1px solid $main-orange;
            display: inline-block;
            padding-right: 10px;
            p{
               margin: 0;
               font-size: 18px !important;
               span{
                  color: $main-orange;
               }
               a{
                  color: $main-white;
                  text-decoration: none;
                  transition: all 0.4s;
                  &:hover{
                     color: $main-orange;
                  }
               }
            }
         }
         .about-content-inner{
            max-width: 605px;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            color: $main-white;
            @include max-mq(991px){
               margin: auto;
               padding: 0;
            }
            h2,h3,h4,h5,h6{
               color: $main-white;
            }
            p{
               font-size: 16px;
               letter-spacing: 0.03em;
               line-height: 30px;
            }
            h3{
               margin-top: 0;
               margin-bottom: 65px;
               line-height: 40px;
               letter-spacing: 0.03em;
               @include max-mq(1024px){
                  margin-bottom: 40px;
               }
            }
            ul{
               margin-left: -15px !important;
               li{
                  font-size: 16px;
                  line-height: 40px;
               }
            }
         }
      }
   }
}