#return-to-top {
   position: fixed;
   bottom: 20px;
   right: 20px;
   background-color: rgba(red, 0.8);
   width: 50px;
   height: 50px;
   display: block;
   text-decoration: none;
   border-radius: 50%;
   display: none;
   transition: all 0.4s;
   line-height: 3.1;
   text-align: center;
   cursor: pointer;
   z-index: 999;
   -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
   &:hover{
      background-color: red;
   }
   i {
      color: #fff;
      margin: auto;
      position: relative;
      font-size: 19px;
      transition: all 0.3s ease;
   }
}