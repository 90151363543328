section.hero-section {
  .hero-section-wrapper{
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      border-style: solid;
      z-index: 4;
      border-width: 60px 30vw 60px 30vw;
      display: block;
      max-width: 50vw;
      // @include max-mq(1280px){
      //   border-width: 60px calc( 25vw + 70px ) 60px calc( 25vw + 70px );
      // }
      // @include max-mq(991px){
      //   border-width: 60px calc( 25vw + 60px ) 60px calc( 25vw + 60px );
      // }
      @include max-mq(1024px){
        border-width: 30px 30vw 30px 30vw;
        bottom: 0;
      }
      @include max-mq(767px){
         border-width: 20px 30vw 20px 30vw ;
      }
    }
    &:before {
      border-color: transparent  transparent $blue-gray  $blue-gray;
      left: 0;
    }
    &:after {
      border-color: transparent $blue-gray $blue-gray transparent;
      right: 0;
    }
  }
  .hero-section-inner {
    position: relative;
    .promo-wrapper {
      position: absolute;
      top: 0;
      right: calc( (100vw - 1480px) / 2 + 20px );
      width: 165px;
      padding: 20px 25px 25px;
      background-color: $main-red;
      color: $main-white;
      font-size: 20px;
      font-weight: $fw-light;
      letter-spacing: 0.05em;
      line-height: 30px;
      text-align: center;
      z-index: 2;

      @include max-mq(1480px){
        right: 10px;
      }
      @include max-mq(1024px){
        right: 0;
        width: 100%;
        text-align: center;
        padding: 5px;
      }
      @include max-mq(767px){
        font-size: 12px;
        line-height: 1.2;
      }
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      border-style: solid;
      z-index: 5;
      border-width: 50px 25vw 50px 25vw;
      display: block;
      max-width: 50vw;
      @include max-mq(1024px){
        border-width: 25px 25vw 25px 25vw;
        bottom: 0;
      }
      @include max-mq(767px){
         border-width: 15px 25vw 15px 25vw ;
      }
    }
    &:before {
      border-color: transparent  transparent $main-white  $main-white;
      left: 0;
    }
    &:after {
      border-color: transparent $main-white $main-white transparent;
      right: 0;
    }
  }
  .hero-slider {
    .slick-dots {
      position: absolute;
      left: calc((100vw - 1480px) / 2 + 20px);
      bottom: 180px;
      @include max-mq(1480px){
        left: 20px;
      }
      @include max-mq(1024px){
        bottom: 80px;
        left: 0;
        right: 0;
        margin: auto;
      }
      @include max-mq(767px){
        bottom: 50px;
      }
    }
    .slick-track{
      @include max-mq(1024px){
        display: flex !important;
        flex-direction: row;
        .slick-slide{
          display: flex !important;
          width: 100%;
          height: auto !important;
          >div{
            width: 100%;
            height: 100%;
            display: flex !important;
          }
        }
      }
      
    }
  }
  .hero-slide {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 70px);
    @include max-mq(1024px){
      min-height: 600px;
      height: 100%;
    }
    @include max-mq(360px){
      min-height: 500px;
    }
    h1 {
      margin-top: 0;
      text-transform: uppercase;
      font-weight: $fw-thin;
    }
    .button-wrapper{
      padding-top: 40px;
      @include max-mq(1024px){
        padding-top: 30px;
      }
      @include max-mq(767px){
        padding-top: 20px;
      }
      @include max-mq(340px){
        padding-top: 0px;
      }
    }
    &.hero-slide-with-content {
      position: relative;
      &::before {
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        display: block;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#172c54+0,06142e+60&0.85+0,0.01+60 */
        background: -moz-linear-gradient(left, rgba(23, 44, 84, 0.75) 0%, rgba(6, 20, 46, 0.01) 60%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          rgba(23, 44, 84, 0.75) 0%,
          rgba(6, 20, 46, 0.01) 60%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          rgba(23, 44, 84, 0.75) 0%,
          rgba(6, 20, 46, 0.01) 60%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9172c54', endColorstr='#0306142e',GradientType=1 ); /* IE6-9 */
        
        @include max-mq(1024px){
          background: -moz-linear-gradient(bottom, rgba(23, 44, 84, 0.75) 0%, rgba(6, 20, 46, 0.01) 85%); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            bottom,
            rgba(23, 44, 84, 0.75) 0%,
            rgba(6, 20, 46, 0.01) 85%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to top,
            rgba(23, 44, 84, 0.75) 0%,
            rgba(6, 20, 46, 0.01) 85%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9172c54', endColorstr='#0306142e',GradientType=1 ); /* IE6-9 */
        
        }
      }
    }
    &.hero-slide-1 {
      background-image: url("../images/slide1.jpg");
      @include max-mq(460px){
        background-image: url("../images/slide1-mobile.jpg");
      }
     
    }
    &.hero-slide-2 {
      background-image: url("../images/slide2.jpg");
      @include max-mq(460px){
        background-image: url("../images/slide2-mobile.jpg");
      }
      .slide-content-inner {
        margin-top: auto;
        padding-bottom: 80px;
      }
    }
    &.hero-slide-3 {
      background-image: url("../images/slide3.jpg");
      @include max-mq(460px){
        background-image: url("../images/slide3-mobile.jpg");
      }
      .button-wrapper{
        // @include min-mq(1025px){
        //   padding-top: 80px;
        // }
        @include max-mq(340px){
          padding-top: 20px;
        }
      }
      .slide-content-inner{
        @include min-mq(1025px){
          padding-top: 50px;
        }
      }
    }
    .slide-content {
      display: flex;
      height: calc(100% - 230px);
      align-items: center;
      flex-direction: row;
      position: relative;
      z-index: 2;
      @include max-mq(1024px){
        padding-top: 190px;
        padding-bottom: 150px;
        height: calc(100% - 100px);
      }
      @include max-mq(767px){
        padding-bottom: 120px;
      }
      @include max-mq(340px){
        padding-top: 150px;
        padding-bottom: 100px;
      }
      
      .slide-content-inner {
        max-width: 585px;
        @include max-mq(1024px){
          margin: auto;
          width: 100%;
          text-align: center;
        }
        >div{
          width: 100%;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $main-white;
          font-weight: $fw-light;
        }
        h1{
           margin-bottom: 30px;
           @include max-mq(767px){
             margin-bottom: 20px;
           }
        }
        .side-text {
          // margin: auto;
        }
      }
    }
  }
}
